@charset "UTF-8";
$ionicons-font-path: "/Fonts" !default;
$ionicons-font-family: "Ionicons" !default;
$ionicons-version: "3.0.0-alpha.3" !default;

@import url("https://fonts.googleapis.com/css?family=Lato:400,900,700");
@import "../../node_modules/ionicons/dist/scss/ionicons.scss";
@import 'theme/helpers';
@import 'theme/user-variables';
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min";
@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import "nojs-menus.scss";
@import 'theme/reboot';
@import 'theme/components';
@import 'theme/utilities';
@import 'theme/user';

@import './components';


/**
 * Fix for Reactstrap
 **/
.react-bs-table-sizePerPage-dropdown .dropdown-item a {
  display: block;
  color: #000;
  text-decoration: none;
}

.react-bs-table-sizePerPage-dropdown button {
  background: #fff;
  color: #000;
}

