//
// user.scss
// Use this to write your custom SCSS
//
.StripeElement {
  font-size: 20px;
}

.CardField {
  border: 1px solid rgba(0, 0, 0, 0.085);
}
