//
// Carousel (based on Owl Carousel)
// --------------------------------------------------



  // Navs
  .image-gallery-slide-wrapper span {
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include border-radius($carousel-navs-border-radius);
    box-shadow: $carousel-navs-box-shadow;
    z-index:10000;
  }
  .image-gallery-slide-wrapper button.owl-next,
  .image-gallery-slide-wrapper button.owl-prev {
    display: block;
    border:none;
    float: left;
    width: $carousel-navs-size;
    height: $carousel-navs-size;
    transition: all .3s;
    background-color: $carousel-navs-bg;
    color: $carousel-navs-arrow-color;
    line-height: $carousel-navs-size;
    text-align: center;
    text-align: center;
    opacity: 1;
    > span { display: none; }
    &:hover:not(.disabled) {
      color: $carousel-navs-hover-arrow-color;
    }
    &:focus { outline: none; }
    &::after {
      display: inline-block;
      line-height: 1;
      font-size: $carousel-navs-arrow-size;
    }
    &.disabled {
      color: $gray-500;
      cursor: not-allowed;
    }
  }
  .image-gallery-slide-wrapper button.owl-prev {
    &::after {
      margin: {
        top: .275rem;
        left: -.125rem;
      }
      content: '‹';
    }
}
  .image-gallery-slide-wrapper button.owl-next {
    border-left: $border-width solid rgba($border-color, .55);
    &::after {
      margin: {
        top: .275rem;
        right: -.125rem;
      }
      content: '›';
    }
  }


 

  .controls-hidden-mobile {
    @include media-breakpoint-down(md) {
      .owl-nav {
        display: none;
      }
    }
  }

